<template>
  <div class="amenities-edit animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2 pb-1">
          <h4 class="mainTitle lineBottom">Amenity Content</h4>
          <div class="main-contents">
            <fd-input
              class="mt-2"
              type="text"
              :label="`Amenity Name`"
              v-model="amenity.name"
              :required="true"
            />
            <fd-textarea
              class="mt-2"
              :label="`Amenity Description`"
              v-model="amenity.description"
              :rows="8"
            />
          </div>
          <div class="text-right px-2 mt-4 mb-2">
            <button type="submit" class="btn main">Update</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { AmenityModel } from "@/models";

export default {
  name: "amenities-edit",
  data() {
    return {
      amenity: {
        name: "",
        description: ""
      }
    };
  },
  mounted() {
    this.loadAmenity();
  },
  methods: {
    async loadAmenity() {
      const id = this.$route.params.id;
      try {
        const request = await this.$store.dispatch(
          "manageAmenities/getAmenity",
          id
        );

        this.amenity = AmenityModel.getAmenityResponse(request);

        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    },
    async submitForm() {
      const id = this.$route.params.id;
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        const request = await this.$store.dispatch(
          "manageAmenities/updateAmenities",
          {
            id: id,
            data: AmenityModel.postAmenityPayload(this.amenity)
          }
        );

        alert("Amenity updated successfully.");
        this.$router.push({ path: "/manage-amenities" });
        this.$store.commit("setIsLoading", false, { root: true });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.amenities-edit {
  form {
    @extend %formDesign;
  }
}
</style>